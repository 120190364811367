// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-pages-index-js": () => import("./../../../content/pages/index.js" /* webpackChunkName: "component---content-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-archive-js": () => import("./../../../src/templates/categoryArchive.js" /* webpackChunkName: "component---src-templates-category-archive-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

